export const HOME = '/';
export const MINE = '/mine';
export const MINE_CATEGORIES = '/mine/select-category';
export const MINE_WITH_TAG = '/mine/:tag';
export const PUBLIC = '/public';
export const SIGN_UP = '/sign-up';
export const SIGN_IN = '/sign-in';
export const SIGN_OUT = '/sign-out';
export const SETTINGS = '/settings';
export const CONVERSATIONS = '/conversations';
export const RESET_PASSWORD = '/reset-password';
export const PRIVACY_POLICY = '/privacy-policy';
export const SUPPORT_PAGE = '/support';
export const TAG_PAGE = '/t/:tag';
export const USER_PROFILE = '/:username';
export const INSIGHT_PAGE = '/:username/:insightId';
