/*
 * Component at bottom of insights which allows users to report inappropriate content
 */

import React from 'react';

import { withFirebase } from './Firebase';
import { AuthUserContext } from './Session';

class ReportContent extends React.Component {
  constructor(props) {
    super(props);

    this.db = this.props.firebase.db;
    this.handleReportClick = this.handleReportClick.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleReportSubmit = this.handleReportSubmit.bind(this);

    this.state = {
      reportClicked: false,
      reportSubmitted: false,
    };
  }

  handleReportClick() {
    this.setState({ reportClicked: true });
  }

  handleCancel() {
    this.setState({ reportClicked: false });
  }

  handleReportSubmit() {
    this.db
      .collection('reportedInsights')
      .add({
        date: new Date(),
        insightRef: this.db.collection('insights').doc(this.props.insightId),
        reportedBy: this.db.collection('users').doc(this.context.uid),
        flaggedForReview: true,
      })
      .then(() => {
        this.setState({ reportSubmitted: true });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    return (
      <div id='ReportContent'>
        {this.state.reportClicked === false && (
          <small id='ReportContentLink' onClick={this.handleReportClick}>
            <ion-icon name="alert-outline"></ion-icon> Report
          </small>
        )}
        {this.state.reportClicked === true && this.state.reportSubmitted === false && (
          <small>
            Do you want to report this conversation for review? This post will be reviewed by our
            team within 24 hours.
            <div>
              <button type='submit' className='warning' onClick={this.handleReportSubmit}>
                Report
              </button>
              <button type='submit' onClick={this.handleCancel}>
                Cancel
              </button>
            </div>
          </small>
        )}
        {this.state.reportClicked === true && this.state.reportSubmitted === true && (
          <small>Thank you for your report. We will aim to review this post within 24 hours.</small>
        )}
      </div>
    );
  }
}

ReportContent.contextType = AuthUserContext;

export default withFirebase(ReportContent);
