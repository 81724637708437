import React, { useContext, useState } from 'react';

import { Redirect, Link } from 'react-router-dom';
import styled from 'styled-components';
import SignOutButton from './SignOut';
import PasswordChange from './PasswordChange';
import { AuthUserContext } from './Session';
import { withFirebase } from './Firebase';

import {
  SetPageTitle,
  ScheduleNotifications,
  CancelPendingNotifications,
  GetSetting,
} from '../Utils.js';

import * as ROUTES from '../constants/routes.js';
import BlockedUsers from './BlockedUsers';

import { Plugins } from '@capacitor/core';

const { Device, LocalNotifications } = Plugins;

const Settings = props => {
  SetPageTitle('Settings');

  const currUser = useContext(AuthUserContext) || {};

  const [state, setState] = useState({
    reminderFreq: GetSetting('reminderFreq', currUser) || 'off',
    firstName: currUser.firstName,
    lastName: currUser.lastName,
  });

  if (!currUser.uid) return <Redirect to={ROUTES.HOME} />;

  const handleChange = e => {
    const fields = ['firstName', 'lastName'];
    if (!fields.includes(e.target.name)) return;

    setState({
      ...state,
      [e.target.name]: e.target.value,
    });

    currUser.update(e.target.name, e.target.value);
  };

  /*
   * We store the reminder frequency in the database,
   * but also need to schedule it as LocalNotifications on the device.
   */
  const handleFreqChange = async e => {
    let newFreq = e.target.value;

    if (!['daily', 'off'].includes(newFreq)) return;

    currUser.update('settings', { reminderFreq: newFreq });
    setState({ ...state, reminderFreq: newFreq });

    /*
     * We allow updating from desktop, but only want to schedule
     * if we're on the device
     */
    let deviceInfo = await Device.getInfo();
    if (deviceInfo.platform !== 'ios') return;

    /*
     * First make sure to clear all pending notifications from any other
     * type of reminder frequency.
     */
    await CancelPendingNotifications();

    if (newFreq !== 'off') {
      const enabled = await LocalNotifications.areEnabled();
      if (enabled.value === true) {
        ScheduleNotifications(newFreq);
      } else {
        const granted = await LocalNotifications.requestPermission();
        if (granted) {
          ScheduleNotifications(newFreq);
        } else {
          alert(
            "Can't send notifications without permission - please go to Settings to enable notifications for the app."
          );
        }
      }
    }
  };

  return (
    <section id='Settings' className={props.className}>
      <section>
        <div className='sectionInner'>
          <h2>Settings</h2>
          <p>
            Logged in as: <strong>{currUser.username}</strong>.
          </p>
          <p>
            <input
              type='text'
              name='firstName'
              onChange={handleChange}
              value={state.firstName}
              placeholder='First name'
            />
            <input
              type='text'
              name='lastName'
              onChange={handleChange}
              value={state.lastName}
              placeholder='Last name'
            />
          </p>
          <p id='ReminderFreqSelector'>Reminders from iOS app:</p>
          <p>
            <label>
              <input
                type='radio'
                name='reminderFreq'
                value='daily'
                onChange={handleFreqChange}
                checked={state.reminderFreq === 'daily'}
              />
              Daily
            </label>

            <label>
              <input
                type='radio'
                name='reminderFreq'
                value='off'
                onChange={handleFreqChange}
                checked={state.reminderFreq === 'off'}
              />
              Off
            </label>
          </p>
          <div>
            <PasswordChange />
            <SignOutButton />
            <BlockedUsers />
          </div>
        </div>
      </section>
      <section>
        <div className='sectionInner'>
          <div className='settingsSection'>
            <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link> •{' '}
            <a href='mailto:support@todayilearned.co'>Contact</a>
          </div>
        </div>
      </section>
    </section>
  );
};

const StyledSettings = styled(Settings)`
  button {
    margin-bottom: 15px;
  }

  p {
    margin-top: 0px;
  }

  input[name='currentPassword'] {
    margin-bottom: 15px;
  }

  label {
    display: block;
    margin: 0 23px 10px 20px;
    font-size: 16px;

    input {
      margin-right: 4px;
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }

  @media (min-width: 1024px) {
    input + input {
      margin-left: 5px;
    }
  }

  #BlockedUsersTable {
    td {
      vertical-align: middle;
      padding-top: 10px;
    }

    .td-btn {
      padding-left: 20px;

      button {
        margin-bottom: 0px;
      }
    }
  }
`;

export default withFirebase(StyledSettings);
