import React, { useEffect, useContext, useState } from 'react';

import ReactTimeAgo from 'react-time-ago/tooltip';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { AuthUserContext } from './Session';
import { FirebaseContext } from './Firebase';

import { SetPageTitle } from '../Utils.js';

import * as ROUTES from '../constants/routes.js';
import { NavLink } from 'react-router-dom';

import truncate from 'lodash/truncate';

const Conversations = props => {
  SetPageTitle('Conversations');
  const currUser = useContext(AuthUserContext) || {};
  const firebase = useContext(FirebaseContext);

  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);

  useEffect(() => {
    if (!currUser.uid) return;

    const unsub = firebase.db
      .collection('notifications')
      .where('recipientId', '==', currUser.uid)
      .orderBy('createdAt', 'desc')
      .onSnapshot(querySnapshot => {
        let newNotifications = [];
        querySnapshot.forEach(doc => {
          newNotifications.push(
            Object.assign(doc.data(), {
              id: doc.id,
            })
          );
          if (!doc.get('read'))
            // Store unread notifications in a different state or else
            // they'll be overwridden when we update the db
            setUnreadNotifications(unreadNotifs => [...unreadNotifs, doc.id]);
          firebase.db
            .collection('notifications')
            .doc(doc.id)
            .set({ read: true }, { merge: true });
        });
        setNotifications(newNotifications);
      });

    return unsub;
  }, [currUser.uid, firebase]);

  if (!currUser.uid) return <Redirect to={ROUTES.HOME} />;

  const items = notifications.map(notification => {
    if (notification.type === 'newReaction') {
      return (
        <div className='notification' key={notification.id}>
          <NavLink className='user' to={'/' + notification.senderUsername}>
            {notification.senderDisplayName}
          </NavLink>{' '}
          {notification.reactionType === 'thanks' && (
            <span className='icon-reaction'>
              <ion-icon name='happy-outline'></ion-icon> thanked you for sharing{' '}
            </span>
          )}
          {notification.reactionType === 'love' && (
            <span className='icon-reaction'>
              <ion-icon name='heart-outline'></ion-icon> loved your insight{' '}
            </span>
          )}
          <NavLink to={'/' + currUser.username + '/' + notification.insightId}>
            <span className='insight'>
              {truncate(notification.insightText, { length: 50 })}
            </span>
          </NavLink>
          <ReactTimeAgo date={notification.createdAt.toDate()} />
          {unreadNotifications.includes(notification.id) && (
            <div className='dot'></div>
          )}
        </div>
      );
    } else if (notification.type === 'newComment') {
      return (
        <div className='notification' key={notification.id}>
          <NavLink className='user' to={'/' + notification.senderUsername}>
            {notification.senderDisplayName}
          </NavLink>{' '}
          reflected on{' '}
          <NavLink to={'/' + currUser.username + '/' + notification.insightId}>
            <span className='insight'>
              {truncate(notification.insightText, { length: 50 })}
            </span>
          </NavLink>
          <ReactTimeAgo date={notification.createdAt.toDate()} />
          {unreadNotifications.includes(notification.id) && (
            <div className='dot'></div>
          )}
          <NavLink to={'/' + currUser.username + '/' + notification.insightId}>
            <div className='bubble'>{notification.commentText}</div>
          </NavLink>
        </div>
      );
    }
    return '';
  });

  return (
    <section id='Conversations' className={props.className}>
      <section>
        <div className='sectionInner'>
          <h2>Conversations</h2>
          {items.length > 0 ? (
            items
          ) : (
            <p className='blank-slate'>
              Reflections on any public insights you share will show up here.
            </p>
          )}
        </div>
      </section>
    </section>
  );
};

const StyledConversations = styled(Conversations)`
  .blank-slate {
    color: #555;
  }
  .notification {
    padding-bottom: 30px;
    color: #666;

    &:hover a {
      text-decoration: none !important;
    }

    .insight {
      font-weight: 500;
      color: #454545;
    }

    .bubble {
      margin: 15px 0 10px;
      white-space: pre-line;
      background: white;
      border-radius: 15px;
      padding: 13px 19px;
      color: #222;
      display: table;
      font-size: 15px;

      box-shadow: 0px 5px 12px hsla(44, 80%, 20%, 0.06),
        0px 1px 2px hsla(44, 80%, 20%, 0.06);
    }

    .dot {
      margin-left: 5px;
    }

    time {
      font-size: 14px;
      padding-left: 5px;
      color: #ccc;
    }
  }
`;

export default StyledConversations;
