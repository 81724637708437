import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago/tooltip';

import styled from 'styled-components';

const Reaction = props => {
  return (
    <StyledReaction>
      <div id='Reaction'>
        <span className='username'>
          <NavLink className='user' to={'/' + props.reaction.username}>
            {props.reaction.displayName
              ? props.reaction.displayName
              : props.reaction.username}
          </NavLink>
        </span>
        <span className='type'>
          {props.reaction.type === 'love' && (
            <span className='icon-reaction'>
              <ion-icon name='heart-outline'></ion-icon>I love this!
            </span>
          )}
          {props.reaction.type === 'thanks' && (
            <span className='icon-reaction'>
              <ion-icon name='happy-outline'></ion-icon>
              Thanks for sharing!
            </span>
          )}
        </span>
        <span className='date'>
          <ReactTimeAgo date={props.reaction.createdAt.toDate()} />
        </span>
      </div>
    </StyledReaction>
  );
};

const StyledReaction = styled.div`
  margin-left: 26px;
  font-size: 15px;
  margin-bottom: 10px;

  .username a {
    font-size: 15px !important;
  }

  .type {
    margin: 0 10px 0 10px;
    font-size: 15px !important;

    ion-icon {
      margin-right: 5px;
      margin-left: 5px;
    }
  }
`;

export default Reaction;
