import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { withFirebase } from './Firebase';
import { SetPageTitle } from '../Utils.js'

import * as ROUTES from '../constants/routes.js';

const ResetPasswordPage = () => (
  <section id='ResetPasswordPage'>
    <section>
      <div className='sectionInner'>
        <h1>Reset Password</h1>
        <ResetPasswordForm />
      </div>
    </section>
  </section>
);

class ResetPasswordFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: null,
      emailSent: null,
    };
  }

  componentDidMount() {
    SetPageTitle("Reset password");
  }

  onSubmit = event => {
    event.preventDefault();

    const { email } = this.state;

    this.props.firebase
      .doPassWordReset(email)
      .then(() => {
        this.setState({
          email: '',
          error: null,
          emailSent: true,
        });
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error, emailSent } = this.state;

    const isInvalid = email === '';

    return (
      <section className='inputComponent' id='ResetPassword'>
        <form onSubmit={this.onSubmit}>
          <input
            name='email'
            value={email}
            onChange={this.onChange}
            type='text'
            placeholder='Email'
          />
          <button type='submit' disabled={isInvalid}>
            Send Password Reset Email
          </button>
          {emailSent && <p>Email sent. Please check your inbox</p>}
          {error && <p>{error.message}</p>}
        </form>
      </section>
    );
  }
}

const ResetPasswordLink = () => (
  <Link to={ROUTES.RESET_PASSWORD}>Forgot your password?</Link>
);

const ResetPasswordForm = withRouter(withFirebase(ResetPasswordFormBase));

export default ResetPasswordPage;

export { ResetPasswordForm, ResetPasswordLink };
