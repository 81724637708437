import React from 'react';

import Category from './Category';

function Categories(props) {
  const items = Object.entries(props.categories)
    .sort((a, b) => b[1] - a[1])
    .map((category, i) => {
      return (
        <div key={i}>
          <Category category={category[0]} count={category[1]} />
        </div>
      );
    });

  return (
    <div className='categoriesList'>
      <Category category='All posts' link='/'></Category>
      {items}
    </div>
  );
}

export default Categories;
