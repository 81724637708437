import mixpanel from 'mixpanel-browser';
mixpanel.init('effb972fd353a2cca1dd3523096ba18b');

let env_check = process.env.NODE_ENV === 'production';

let actions = {
  identify: id => {
    if (env_check) mixpanel.identify(id);
  },
  alias: id => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  reset: () => {
    if (env_check) mixpanel.reset();
  },
  people: {
    set: props => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
