import React from 'react';

import { withFirebase } from './Firebase';
import { AuthUserContext } from './Session';
import { Mixpanel } from '../Mixpanel';

import AutosizeInput from 'react-input-autosize';

class InsightEditor extends React.Component {
  constructor(props) {
    super(props);

    this.MAX_TAGS = 2;

    if (props.insight) {
      let tags = [''];
      if (props.insight.tags) {
        tags = [...props.insight.tags];
        if (tags.length < this.MAX_TAGS && !tags.includes('')) {
          tags.push('');
        }
      }

      this.state = {
        text: props.insight.text,
        isPublic: props.insight.isPublic,
        tags: tags,
      };
    } else {
      this.state = {
        text: '',
        isPublic: false,
        tags: props.currentTag ? [props.currentTag] : [''],
      };
    }

    this.db = this.props.firebase.db;

    this.handleChange = this.handleChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTextareaKeyDown = this.handleTextareaKeyDown.bind(this);
    this.handleTagKeyDown = this.handleTagKeyDown.bind(this);
    this.handleTagChange = this.handleTagChange.bind(this);
  }

  handleRadioChange(e) {
    this.setState({
      isPublic: e.target.value === 'true',
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleTextareaKeyDown(e) {
    // Submit on Ctrl/CMD + enter
    if (e.keyCode === 13 && e.metaKey) {
      this.handleSubmit(e);
      return;
    }

    // If you're typing `#`, move to next available tag
    if (e.keyCode === 51) {
      let tagInput = document
        .getElementsByClassName('tagContainer')[0]
        .getElementsByTagName('input')[0];
      if (tagInput.value === '') {
        tagInput.focus();
      } else {
        // Focus on the second tag, hopefully more space there ¯\_(ツ)_/¯
        document
          .getElementsByClassName('tagContainer')[1]
          .getElementsByTagName('input')[0]
          .focus();
      }

      e.preventDefault();
      return;
    }
  }

  handleTagKeyDown(e) {
    // Prevent spaces from existing in tags
    if (e.keyCode === 32) {
      e.preventDefault();
      return;
    }

    // Submit on Ctrl/CMD + enter
    if (e.keyCode === 13 && e.metaKey) {
      this.handleSubmit(e);
      return;
    }
  }

  /*
   * Manage automatic addition and removal of tags
   */
  handleTagChange(index, e) {
    let tags = this.state.tags.slice();
    tags[index] = e.target.value;

    tags = tags.filter(tag => {
      return tag !== '';
    });

    if (tags.length < this.MAX_TAGS && !tags.includes('')) tags.push('');

    this.setState({
      tags: tags,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    let text = this.state.text;
    let isPublic = this.state.isPublic === true;
    let tags = this.state.tags.filter(tag => tag !== '');

    if (!text || !this.context.uid) return;

    let insight = {
      date: new Date(),
      text: text,
      isPublic: isPublic,
      username: this.context.username,
      user: this.context.uid,
      userRef: this.db.collection('users').doc(this.context.uid),
      numComments: 0,
      numReactions: 0,
    };

    if (tags.length) {
      insight.tags = tags;
      insight.tagsLower = tags.map(tag => tag.toLowerCase());
    }

    if (this.context.firstName)
      insight.displayName =
        this.context.firstName + ' ' + this.context.lastName;

    if (this.props.insight) insight.id = this.props.insight.id;

    // Are we editing?
    if (this.props.insight) {
      this.props.handleSubmit(insight);
    } else {
      this.db
        .collection('insights')
        .add(insight)
        .catch(error => {
          console.error('Error adding document:' + error);
        });

      this.setState({ text: '', tags: [''], isPublic: false });
      Mixpanel.track('Posted an insight', {
        visibility: isPublic ? 'public' : 'private',
        numTags: insight.tags ? insight.tags.length : 0,
      });
      this.props.afterSubmit(insight);
    }
  }

  render() {
    return (
      <div id='InsightEditor'>
        <form onSubmit={this.handleSubmit}>
          <textarea
            onChange={this.handleChange}
            onKeyDown={this.handleTextareaKeyDown}
            placeholder='What did you learn today?'
            maxLength='110'
            name='text'
            value={this.state.text}
          />
          <div id='Tags'>
            {this.state.tags.map((tags, index) => (
              <span className='tagContainer' key={index}>
                <span className='hashtag'>#</span>
                <AutosizeInput
                  type='text'
                  placeholder='Add tag...'
                  maxLength='22'
                  size='9'
                  value={this.state.tags[index]}
                  onKeyDown={this.handleTagKeyDown}
                  onChange={this.handleTagChange.bind(this, index)}
                />
              </span>
            ))}
          </div>
          <span id='PrivacyToggle'>
            <label>
              <input
                type='radio'
                name='isPublic'
                value='false'
                onChange={this.handleRadioChange}
                checked={this.state.isPublic === false}
              />
              Private
            </label>

            <label>
              <input
                type='radio'
                name='isPublic'
                value='true'
                onChange={this.handleRadioChange}
                checked={this.state.isPublic === true}
              />
              Public
            </label>
          </span>
          <span id='Submit'>
            <input
              type='submit'
              value={this.props.insight ? 'Update' : 'Save'}
            />
            {this.props.insight && (
              <button
                type='button'
                className='cancel'
                onClick={this.props.handleCancel}
              >
                Cancel
              </button>
            )}
          </span>
        </form>
      </div>
    );
  }
}
InsightEditor.contextType = AuthUserContext;

export default withFirebase(InsightEditor);
