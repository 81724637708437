import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const MineStreak = props => {
  const [recentPosts, setRecentPosts] = useState([]);

  // Create an array of last seven days
  const lastSevenDays = [...Array(7)]
    .map((_, index) => {
      let newDate = new Date();
      newDate.setDate(new Date().getDate() - index);
      newDate.setHours(0, 0, 0, 0);
      return newDate;
    })
    .reverse();

  // Check if there were posts during the last 7 days
  useEffect(() => {
    let sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(new Date().getDate() - 7);
    sevenDaysAgo.setHours(0, 0, 0, 0);

    setRecentPosts(() => {
      return props.insights
        .filter(insight => {
          insight.date.setHours(0, 0, 0, 0);
          return insight.date > sevenDaysAgo;
        })
        .map(insight => insight.date.valueOf());
    });
  }, [props.insights]);

  return (
    <StyledStreak>
      {lastSevenDays.map((day, i) => {
        let posted = recentPosts.includes(day.valueOf());
        return (
          <StyledDay key={i} className={posted ? 'posted' : ''}>
            {day.getDate()}
          </StyledDay>
        );
      })}
    </StyledStreak>
  );
};

const StyledStreak = styled.div`
  text-align: center;
  margin-bottom: 25px;
  margin-top: 12px;
`;

const StyledDay = styled.div`
  display: inline-block;
  margin-right: 8px;
  background: white;
  padding: 7px;
  border-radius: 25px;
  width: 19px;
  height: 19px;
  text-align: center;
  color: #888;
  font-size: 15px;

  &.posted {
    background: gold;
    color: white;
    font-weight: bold;
  }
`;

export default MineStreak;
