import React from 'react';

import { Link } from 'react-router-dom';

import { withFirebase } from './Firebase';
import { AuthUserContext } from './Session';
import { SanitizeText } from '../Utils';

import * as ROUTES from '../constants/routes.js';

class AddComment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: ''
    };

    this.db = this.props.firebase.db;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    const text = SanitizeText(this.state.text);
    if (!text || !this.context.uid) return;

    const comment = {
      createdAt: new Date(),
      text: text,
      username: this.context.username,
      userRef: this.db.collection('users').doc(this.context.uid)
    };

    if (this.context.firstName)
      comment.displayName =
        this.context.firstName + ' ' + this.context.lastName;

    this.db
      .collection('insights')
      .doc(this.props.insightId)
      .collection('comments')
      .add(comment)
      .catch(error => {
        console.error('Error adding document:' + error);
      });

    this.setState({ text: '' }, () => {
      this.updateTextareaHeight(document.getElementById('commentTextarea'));
    });
  }

  // Adjust textarea height to content
  updateTextareaHeight(el) {
    el.style.height = '74px'; // Needs to be mirrored in CSS
    el.style.height = `${el.scrollHeight + 3}px`;
  }

  handleChange(e) {
    // Grow and shrink automatically
    this.updateTextareaHeight(e.target);

    this.setState({ [e.target.name]: e.target.value });
  }

  handleKeyDown(e) {
    if (e.keyCode === 13 && e.metaKey) {
      this.handleSubmit(e);
    }
  }

  scrollToBottom() {
    function goToBottom() {
      var scrollingElement = document.scrollingElement || document.body;
      scrollingElement.scrollTop = scrollingElement.scrollHeight;
    }
    setTimeout(goToBottom, 600);
  }

  render() {
    if (!this.context) {
      return (
        <div id='LoggedOut'>
          <p>
            <Link to={ROUTES.SIGN_IN}>Log in</Link> or{' '}
            <Link to={ROUTES.SIGN_UP}>sign up</Link> to add a comment.
          </p>
        </div>
      );
    } else {
      return (
        <div id='AddComments'>
          <form onSubmit={this.handleSubmit}>
            <textarea
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              onFocus={this.scrollToBottom}
              name='text'
              id='commentTextarea'
              value={this.state.text}
              placeholder='Share something constructive...'
              maxLength='2000'
            ></textarea>
            <p>
              <input type='submit' value='Submit' />
            </p>
          </form>
        </div>
      );
    }
  }
}

AddComment.contextType = AuthUserContext;

export default withFirebase(AddComment);
