import React from 'react';
import InsightEditor from './InsightEditor';

function MineBlankSlate(props) {
  return (
    <section id='Mine'>
      <div className='blankSlate'>
        <h1>
          welcome!{' '}
          <span role='img' aria-label='High five!'>
            🙌
          </span>
        </h1>
        <p>Let's get your first insight posted!</p>
        <p>
          What did you learn recently? Perhaps about <em>#design</em>,{' '}
          <em>#parenting</em> or <em>#cooking</em> — you name it!
        </p>
      </div>
      <section>
        <div className='sectionInner'>
          <InsightEditor afterSubmit={props.afterSubmit} />
        </div>
      </section>
    </section>
  );
}

export default MineBlankSlate;
