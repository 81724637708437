/*
 * Component on User Profile which allows users to block a user's insights and comments
 */

import React from 'react';
import { Plugins } from '@capacitor/core';

import { withFirebase } from './Firebase';
import { AuthUserContext } from './Session';
const { Modals } = Plugins;

class BlockUser extends React.Component {
  constructor(props) {
    super(props);

    this.db = this.props.firebase.db;
    this.handleBlockClick = this.handleBlockClick.bind(this);
    this.handleBlockSubmit = this.handleBlockSubmit.bind(this);

    this.state = {
      blockSubmitted: false,
    };
  }

  async handleBlockClick() {
    let confirmVal = await Modals.confirm({
      title: 'Confirm',
      message:
        "Do you want to block this user's insights and reflections? They will not be notified.",
    });

    confirmVal.value === true && this.handleBlockSubmit();
  }

  async handleBlockSubmit() {
    try {
      // Get document from users collection for the user being blocked to get the document reference
      let blockedUserDoc = await this.db
        .collection('users')
        .where('username', '==', this.props.username)
        .get()
        .then(snapshot => {
          if (snapshot.docs.length === 1) {
            return snapshot.docs[0];
          } else {
            throw Error('More than one user returned');
          }
        });

      // Create new document in 'blockedUsers' collection to record blocking info
      this.db
        .collection('blockedUsers')
        .add({
          date: new Date(),
          blockedUser: blockedUserDoc.get('username'),
          blockedUserRef: blockedUserDoc.ref,
          blockedBy: this.context.username,
          blockedByRef: this.db.collection('users').doc(this.context.uid),
        })
        .then(() => {
          this.setState({ blockSubmitted: true });
        });
    } catch (error) {
      console.log(error);
      this.setState({ blockSubmitted: false });
    }
  }

  render() {
    if (!this.context) return null;
    return (
      <div id='BlockUser'>
        {/* If not already blocked and it's not the logged in user's profile, display 'Block' link */
        this.state.blockSubmitted === false &&
          this.props.username !== this.context.username && (
            <small id='BlockUserLink' onClick={this.handleBlockClick}>
              <ion-icon name="warning-outline"></ion-icon> Block
            </small>
          )}
        {this.state.blockSubmitted === true && (
          <small>This user's insights and reflections will no longer be visible to you</small>
        )}
      </div>
    );
  }
}

BlockUser.contextType = AuthUserContext;

export default withFirebase(BlockUser);
