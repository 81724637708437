import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { SignUpLink } from './SignUp';
import { ResetPasswordLink } from './ResetPassword'
import { withFirebase } from './Firebase';
import { SetPageTitle } from '../Utils.js'

import * as ROUTES from '../constants/routes';

const SignInPage = () => (
  <section id="SignIn">
    <section>
      <div className='sectionInner'>
        <SignInForm />
        <p>
          <ResetPasswordLink /> • <SignUpLink />
        </p>
      </div>
    </section>
  </section>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null
}

class SignInFormBase extends Component {
  constructor(props) {
    super (props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    SetPageTitle("Sign In");
  }

  onSubmit = (e) => {
    this.props.firebase
      .doSignInWithEmailAndPassword(this.state.email, this.state.password)
      .then(() => {
        this.setState({...INITIAL_STATE});
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    e.preventDefault();
  }

  onChange = (e) => (
    this.setState({ [e.target.name]: e.target.value })
  )

  render() {
    const isInvalid =
      this.state.password === '' ||
      this.state.email === '';

    return (
      <form onSubmit={this.onSubmit} id="SignIn" className='inputComponent'>
        <input
          name="email"
          type="email"
          value={this.state.email}
          onChange={this.onChange}
          placeholder="E-mail address"
        />
        <input
          name="password"
          type="password"
          value={this.state.password}
          onChange={this.onChange}
          placeholder="Password"
        />
        <p>
          <button disabled={isInvalid} type="submit">
            Sign In
          </button>
        </p>

        {this.state.error && <p>{this.state.error.message}</p>}
      </form>
    );
  }
}

const SignInForm = withRouter(withFirebase(SignInFormBase));

export default SignInPage;

export { SignInForm };
