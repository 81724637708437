import React from 'react';

import BackButton from './BackButton';
import ShareButton from './ShareButton';
import styled from 'styled-components';

import { NavLink, withRouter } from 'react-router-dom';

const InsightCard = props => {
  const insight = props.insight;

  return (
    <StyledInsightCard>
      <div>
        <ShareButton insight={insight} />
        <BackButton />
        <div className='insightCard'>
          <div className='insightText'>{insight.text}</div>

          {insight.tags && insight.tags.length > 0 && (
            <div className='tags'>
              {insight.tags &&
                insight.tags.map((value, index) => (
                  <NavLink className='tag' to={'/t/' + value} key={index}>
                    #{value}
                  </NavLink>
                ))}
            </div>
          )}
        </div>

        <div className='metadata'>
          <NavLink className='user' to={'/' + insight.username}>
            {insight.displayName && insight.displayName}
            {!insight.displayName && insight.username}
          </NavLink>
          <span className='date'>
            {' '}
            {insight.date.toLocaleString('default', { month: 'short' })}{' '}
            {insight.date.getDate()}, {insight.date.getFullYear()}{' '}
          </span>
          {insight.isPublic === true && (
            <span
              className='visibility icon-with-label muted'
              title='This insight is public, anyone can see it.'
            >
              <ion-icon name='earth-outline'></ion-icon>
              Public
            </span>
          )}
          {insight.isPublic !== true && (
            <span
              className='visibility icon-with-label muted'
              title='Only you can see this.'
            >
              <ion-icon name='lock-closed-outline'></ion-icon>
              Private
            </span>
          )}
        </div>
        <span className='actions'>
          {props.isAuthor && (
            <>
              <button onClick={props.handleEdit} className='icon'>
                <ion-icon name='pencil-sharp'></ion-icon> Edit
              </button>
              <button onClick={props.handleDelete} className='icon'>
                <ion-icon name='trash-outline'></ion-icon> Delete
              </button>
            </>
          )}
        </span>
      </div>
    </StyledInsightCard>
  );
};

const StyledInsightCard = styled.div`
  .insightCard {
    background: white;
    border-radius: 15px;
    padding: 26px 24px;
    box-shadow: 0px 0px 25px rgba(80, 57, 13, 0.02),
      0px 3px 4px rgba(95, 72, 13, 0.08);
    margin-top: 10px;

    @media only screen and (min-width: 810px) {
      padding: 35px 40px 36px;
      margin-top: 0;
    }

    .insightText {
      font-size: 18px;
      line-height: 1.4em;
      font-weight: 400;
      // TODO: Export these variables from app.scss
      font-family: 'Merriweather', 'Roboto', -apple-system, BlinkMacSystemFont,
        'Helvetica Neue', serif;
      color: #474747;
      @media only screen and (min-width: 810px) {
        font-size: 22px;
      }
    }

    .tags {
      margin: 15px 0 0;

      .tag {
        color: $tagTextColor;
        font-size: 18px;
        padding-left: 5px;
        display: inline-block;

        &:hover {
          text-decoration: none;
          color: lighten(saturate($tagTextColor, 5%), 15%);
        }
      }
    }
  }

  .metadata {
    margin: 11px 0;
    font-size: 15px;
    line-height: 2.2em;

    .date {
      color: #bbb;
      font-weight: 400;
      margin-left: 7px;
    }

    .user {
      text-decoration: none;
      color: #333;
      font-weight: 500;
    }

    .visibility {
      margin-left: 7px;
    }
  }

  .actions {
    margin: 17px 0 0;
    display: block;

    button {
      font-size: 14px;
      padding-bottom: 8px;

      ion-icon {
        font-size: 16px;
        position: relative;
        top: 3px;
      }

      & + button {
        margin-left: 20px;
      }
    }
  }
`;

export default withRouter(InsightCard);
