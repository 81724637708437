import React from 'react';

function Spinner() {
  return (
    <section id='Spinner'>
      <section>
        <div className='sectionInner centered'>
            
        <svg width="67" height="67" viewBox="0 0 135 135" xmlns="http://www.w3.org/2000/svg" fill="gold">
          <path d="M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z">
              <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 67 67"
                  to="360 67 67"
                  dur="3.2s"
                  repeatCount="indefinite"/>
          </path>
      </svg>
        </div>
      </section>
    </section>
  );
}

export default Spinner;