import React from 'react';

import { withFirebase } from './Firebase';
import { AuthUserContext } from './Session';
import { NavLink } from 'react-router-dom';
import { GetDayMonthAndYearIfLastYear } from '../Utils.js';

class Insight extends React.Component {
  constructor(props) {
    super(props);

    this.db = this.props.firebase.db;
    this.state = {
      error: '',
    };
  }

  render() {
    const insight = this.props.insight;

    if (this.context && this.context.uid) {
      this.isAuthor = insight.user === this.context.uid;
    }

    return (
      <div className='insightContainer'>
        <NavLink
          className='insightLink'
          to={'/' + insight.username + '/' + insight.id}
        >
          <div className={this.props.list + ' insightInner'}>
            <div className='insight'>{insight.text}</div>
            <div className='metadata'>
              <div className='tags'>
                {insight.tags &&
                  insight.tags.length > 0 &&
                  insight.tags.map((value, index) => (
                    <NavLink className='tag' to={'/t/' + value} key={index}>
                      <span className='numberSign'>#</span>
                      {value}
                    </NavLink>
                  ))}
              </div>
              <div className='date-user-and-icons'>
                {this.props.list === 'public' && (
                  <>
                    <NavLink className='user' to={'/' + insight.username}>
                      {insight.displayName && insight.displayName}
                      {!insight.displayName && insight.username}
                    </NavLink>{' '}
                    <span className='divider-dot'>•</span>
                  </>
                )}
                <span className='date'>
                  {GetDayMonthAndYearIfLastYear(insight.date)}
                </span>
                {((this.props.list === 'mine' && insight.isPublic) ||
                  insight.numComments > 0) && (
                  <span className='icons'>
                    {this.props.list === 'mine' && insight.isPublic && (
                      <span
                        className='visibility icon-gray'
                        title='This insight is public.'
                      >
                        <ion-icon name='earth-outline'></ion-icon>
                      </span>
                    )}
                    {insight.numComments > 0 && (
                      <span className='comments icon-gray'>
                        <ion-icon name='chatbubbles-outline'></ion-icon>
                      </span>
                    )}
                  </span>
                )}
              </div>
            </div>
          </div>
        </NavLink>
      </div>
    );
  }
}
Insight.contextType = AuthUserContext;

export default withFirebase(Insight);
