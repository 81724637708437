import React, { useState, useEffect, useContext } from 'react';

import { AuthUserContext } from './Session';
import { FirebaseContext } from './Firebase';

import styled from 'styled-components';

const InsightReact = props => {
  const insight = props.insight;
  const firebase = useContext(FirebaseContext);
  const currUser = useContext(AuthUserContext) || {};

  const [hasReacted, setHasReacted] = useState([]);

  useEffect(() => {
    // Check if you've reacted already
    if (!insight) return;
    insight.ref.collection('reactions').onSnapshot(querySnapshot => {
      querySnapshot.forEach(reaction => {
        if (reaction.get('user') === currUser.uid) {
          setHasReacted(prevState => [...prevState, reaction.get('type')]);
        }
      });
    });
  }, [insight, currUser.uid]);

  const handleReaction = reaction => {
    const newReaction = {
      user: currUser.uid,
      username: currUser.username,
      displayName: currUser.displayName,
      type: reaction,
      createdAt: new Date(),
    };

    firebase.db
      .collection('insights')
      .doc(insight.id)
      .collection('reactions')
      .add(newReaction)
      .catch(error => {
        console.log(error);
      });
  };

  if (!currUser.uid) return null;

  return (
    <StyledInsightReact>
      {!hasReacted.includes('love') && (
        <button
          onClick={handleReaction.bind(this, 'love')}
          className='icon linky'
        >
          <ion-icon name='heart-outline'></ion-icon> I love this
        </button>
      )}
      {!hasReacted.includes('thanks') && (
        <button
          onClick={handleReaction.bind(this, 'thanks')}
          className='icon linky'
        >
          <ion-icon name='happy-outline'></ion-icon> Thanks for sharing
        </button>
      )}
    </StyledInsightReact>
  );
};

const StyledInsightReact = styled.div`
  button {
    font-size: 14px;
    padding-bottom: 8px;

    ion-icon {
      font-size: 16px;
      position: relative;
      top: 3px;
    }

    & + button {
      margin-left: 20px;
    }
  }
`;

export default InsightReact;
