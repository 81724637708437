import React from 'react';
import styled from 'styled-components';

function MineAlreadyPosted(props) {
  return (
    <StyledMineAlreadyPosted>
      <div className='messageOnTop'>
        <ion-icon name='checkmark-outline'></ion-icon>
        You've reflected today!
        <button
          className='text'
          onClick={() => {
            props.setAddAnother(true);
          }}
        >
          <ion-icon name='add-circle'></ion-icon> Add another
        </button>
      </div>
    </StyledMineAlreadyPosted>
  );
}

const StyledMineAlreadyPosted = styled.div`
  > div {
    margin: -5px 0 -6px !important;
  }
`;

export default MineAlreadyPosted;
