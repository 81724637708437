import React from 'react';

function SupportPage() {
  return (
    <section id='SupportPage'>
      <section>
        <div className='sectionInner'>
          <h2>Support</h2>{' '}
          <p>
            If you have any issues, questions or feedback on Today I Learned, please contact us at{' '}
            <a href='mailto:support@todayilearned.co'>support@todayilearned.co</a>
          </p>{' '}
        </div>
      </section>
    </section>
  );
}

export default SupportPage;
