import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

function Category(props) {
  let link = props.link ? props.link : '/' + props.category;
  return (
    <StyledCategory className='category'>
      <NavLink to={'/mine' + link}>
        <span className='hashtag'>{props.link === '/' ? '' : '#'}</span>
        {props.category}
        <span className='count'>{props.count}</span>
      </NavLink>
    </StyledCategory>
  );
}

const StyledCategory = styled.div`
  a {
    background: white;
    margin-bottom: 12px;
    padding: 20px;
    box-shadow: 0px 2.24917px 2.24917px rgba(211, 206, 194, 0.2),
      0px 4px 13.495px #f3f1ec;
    border-radius: 12px;
    display: block;
    text-decoration: none;
    color: hsla(0, 0%, 27%, 1);
    font-size: 18px;
    font-weight: 400;

    span.count {
      float: right;
      color: hsla(0, 0%, 60%, 1);
    }

    span.hashtag {
      color: hsla(49, 93%, 63%, 1);
    }
  }
`;

export default Category;
