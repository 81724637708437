import React, { Component } from 'react';
import { withFirebase } from './Firebase';

class PasswordChangeSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      passwordOne: '',
      passwordTwo: '',
      message: '',
      showForm: false,
    };

    this.onSubmitPasswordChange = this.onSubmitPasswordChange.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.showForm = this.showForm.bind(this);
  }

  onSubmitPasswordChange = event => {
    event.preventDefault();

    // Validation
    if (this.state.currentPassword === '' || this.state.passwordOne === '') {
      this.setState({ message: 'Password fields cannot be blank' });
      return;
    }
    if (this.state.passwordOne !== this.state.passwordTwo || this.state.passwordOne === '') {
      this.setState({ message: 'New passwords do not match' });
      return;
    }

    this.props.firebase
      .doReauthenticate(this.state.currentPassword)
      .then(() => {
        // User re-authenticated

        this.props.firebase
          .doPasswordUpdate(this.state.passwordOne)
          .then(() => {
            this.setState({
              currentPassword: '',
              passwordOne: '',
              passwordTwo: '',
              message: 'Password changed successfully',
              showForm: false,
            });
          })
          .catch(error => {
            this.setState({ message: error.message });
          });
      })
      .catch(error => {
        this.setState({ message: error.message });
      });
  };

  onChangeInput = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  showForm = event => {
    this.setState({ showForm: true });
  };

  render() {
    return (
      <section className='inputComponent' id='PasswordChangeSection'>
        {!this.state.showForm && (
          <button type='button' onClick={this.showForm}>
            Change Password
          </button>
        )}
        {this.state.showForm && (
          <div>
            <h3>Change Password</h3>
            <form onSubmit={this.onSubmitPasswordChange}>
              <input
                name='currentPassword'
                value={this.state.currentPassword}
                onChange={this.onChangeInput}
                type='password'
                placeholder='Current Password'
                autoComplete='current-password'
              />
              <input
                name='passwordOne'
                value={this.state.passwordOne}
                onChange={this.onChangeInput}
                type='password'
                placeholder='New Password'
                autoComplete='new-password'
              />
              <input
                name='passwordTwo'
                value={this.state.passwordTwo}
                onChange={this.onChangeInput}
                type='password'
                placeholder='Confirm New Password'
                autoComplete='new-password'
              />
              <button type='submit'>Change my password</button>
            </form>
          </div>
        )}
        {this.state.message && <p>{this.state.message}</p>}
      </section>
    );
  }
}

export default withFirebase(PasswordChangeSection);
