import React from 'react';

import { GetMonthAndYear } from '../Utils.js';

import Insight from './Insight';

function Insights(props) {
  let lastMonth = '';
  let realMonth = GetMonthAndYear(new Date());

  const items = props.insights.map((insight, i) => {
    if (insight.visible === false) return false;

    // Insights are grouped by month, so check for a new month
    let currentMonth = GetMonthAndYear(insight.date);
    if (currentMonth !== lastMonth && currentMonth !== realMonth) {
      lastMonth = currentMonth;
      return (
        <div key={i}>
          <h3>{currentMonth.substr(0, 3)}</h3>
          <Insight list={props.list} insight={insight} />
        </div>
      );
    } else {
      return (
        <div key={i}>
          <Insight list={props.list} insight={insight} />
        </div>
      );
    }
  });

  return <div className='insightsList'>{items}</div>;
}

export default Insights;
