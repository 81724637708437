import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { MINE_CATEGORIES } from '../constants/routes';

const CategoryPickerHeader = props => {
  let currentCategory = props.tag || 'All posts';
  return (
    <StyledCategoryPickerHeader>
      <h2>
        <NavLink to={MINE_CATEGORIES + '?current=' + props.tag}>
          {currentCategory}
          <ion-icon name='chevron-down-outline'></ion-icon>
        </NavLink>
      </h2>
    </StyledCategoryPickerHeader>
  );
};

const StyledCategoryPickerHeader = styled.div`
  h2 {
    font-size: 20px;

    a {
      color: #454545;

      &:hover {
        text-decoration: none;
        color: #222;
      }

      ion-icon {
        position: relative;
        top: 3px;
        left: 2px;
        color: hsla(0, 0%, 78%, 1);
      }
    }
  }
`;

export default CategoryPickerHeader;
