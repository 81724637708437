import React from 'react';
import { withFirebase } from './Firebase';
import { AuthUserContext } from './Session';
import { NavLink } from 'react-router-dom';
import { SanitizeText } from '../Utils';
import ReactTimeAgo from 'react-time-ago/tooltip';
import ReactMarkdown from 'react-markdown';

class Comment extends React.Component {
  constructor(props) {
    super(props);

    this.db = this.props.firebase.db;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.state = {
      insightId: this.props.insightId,
      comment: this.props.comment,
      commentText: this.props.comment.text,
      commentBeingEdited: '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    /* Watches for changes in props and updates state to match, but only if comment is not being edited.
     * While comment being edited, allow this component to manage state until it submits changes
     * to Firestore (and thus props change to match local state)
     * This fixed a problem where the sortCommentsAndEdits() method in Comments.js would change the order
     * of comments and edits after the initial page render, thus causing conflicts in local state and props */
    if (
      state.commentText !== props.comment.text &&
      state.commentBeingEdited === ''
    ) {
      return {
        comment: props.comment,
        commentText: props.comment.text,
      };
    } else return null;
  }

  handleEdit(e) {
    e.preventDefault();

    this.setState(
      {
        commentBeingEdited: e.currentTarget.value,
      },
      () => {
        this.sizeTextarea(document.getElementById('EditTextarea'));
      }
    );
  }

  handleSubmit(e) {
    // Submits edited comment to Firestore and creates/updates LastEditDate
    e.preventDefault();
    let sanitizedComment = SanitizeText(this.state.commentText);

    // if no changes have been made, exit
    if (sanitizedComment === this.props.comment.text) {
      this.setState({ commentBeingEdited: '' });
      return;
    }

    this.db
      .collection('insights')
      .doc(this.props.insightId)
      .collection('comments')
      .doc(this.props.comment.id)
      .set(
        {
          text: sanitizedComment,
          lastEditDate: new Date(),
        },
        { merge: true }
      )
      .catch(error => {
        console.log(error);
      });

    // close form
    this.setState({
      commentBeingEdited: '',
      commentText: sanitizedComment,
    });
  }

  sizeTextarea(el) {
    el.style.height = 'inherit';
    el.style.height = `${el.scrollHeight}px`;
  }

  handleChange(e) {
    this.sizeTextarea(e.target);
    this.setState({ commentText: e.target.value });
  }

  handleCancel(e) {
    this.setState({
      commentBeingEdited: '',
      commentText: this.props.comment.text,
    });
  }

  render() {
    return (
      <div id='Comment' className='comment'>
        <span className='username'>
          <NavLink to={'/' + this.props.comment.username}>
            {this.props.comment.displayName && this.props.comment.displayName}
            {!this.props.comment.displayName && this.props.comment.username}
          </NavLink>
        </span>
        <span className='date'>
          <ReactTimeAgo date={this.props.comment.createdAt.toDate()} />
          {this.context &&
            this.props.comment.userRef.id === this.context.uid &&
            !this.state.commentBeingEdited && (
              <button
                onClick={this.handleEdit}
                className='icon'
                id='EditButton'
                value={this.props.comment.id}
              >
                <ion-icon name='pencil-sharp'></ion-icon>
              </button>
            )}
          {this.props.comment.lastEditDate && <span> (edited)</span>}
        </span>

        {/* Show comment edit form when edit comment button pressed */}
        {this.props.comment.id === this.state.commentBeingEdited &&
        this.props.comment.userRef.id === this.context.uid ? (
          <div id='EditComment'>
            <form onSubmit={this.handleSubmit}>
              <textarea
                onChange={this.handleChange}
                name='text'
                value={this.state.commentText}
                maxLength='2000'
                id='EditTextarea'
              />
              <div className='actions'>
                <input type='submit' value='Save' />
                <button
                  type='button'
                  className='cancel'
                  value='Cancel'
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className='text'>
            <ReactMarkdown source={this.props.comment.text} />
          </div>
        )}
      </div>
    );
  }
}

Comment.contextType = AuthUserContext;

export default withFirebase(Comment);
