import React, { useEffect, useState } from 'react';

import { Plugins } from '@capacitor/core';
import styled from 'styled-components';

const { Device, Share } = Plugins;

function BackButton(props) {
  const [deviceInfo, setDeviceInfo] = useState({});

  useEffect(() => {
    (async () => {
      const deviceInfo = await Device.getInfo();
      setDeviceInfo(deviceInfo);
    })();
  }, []);

  async function handleShare() {
    await Share.share({
      title: 'Insight — todayilearned',
      text:
        'Insight by ' + (props.insight.displayName || props.insight.username),
      url:
        'https://todayilearned.app/' +
        props.insight.username +
        '/' +
        props.insight.id,
      dialogTitle: 'Share this insight',
    });
  }

  if (deviceInfo.platform !== 'ios') return null;
  if (props.insight.isPublic !== true) return null;

  return (
    <ShareButtonContainer>
      <button onClick={handleShare} className='unstyled'>
        <ion-icon name='share-outline'></ion-icon>
      </button>
    </ShareButtonContainer>
  );
}

const ShareButtonContainer = styled.div`
  float: right;
  position: relative;
  right: -7px;
  top: 1px;

  ion-icon {
    font-size: 23px;
  }
`;

export default BackButton;
