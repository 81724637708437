import React, { useEffect, useContext, useState } from 'react';

import { AuthUserContext } from './Session';
import { FirebaseContext } from './Firebase';
import { withRouter } from 'react-router-dom';
import { SetPageTitle } from '../Utils.js';

import CommentsAndEdits from './CommentsAndEdits';
import ReportContent from './ReportContent';
import InsightEditor from './InsightEditor';
import InsightCard from './InsightCard';
import InsightReact from './InsightReact';

const InsightPage = props => {
  const firebase = useContext(FirebaseContext);
  const currUser = useContext(AuthUserContext) || {};

  const [insight, setInsight] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');

  SetPageTitle('Insight');

  useEffect(() => {
    // Fetch insight
    // TODO: return cleanup function?
    firebase.db
      .collection('insights')
      .doc(props.match.params.insightId)
      .get()
      .then(doc => {
        if (doc.exists) {
          let insight = doc.data();
          insight.date = insight.date.toDate();
          insight.id = doc.id;
          insight.ref = doc.ref;

          setInsight(insight);
        }
      })
      .catch(error => {
        console.log(error);
        alert('No such insight, or not enough permissions');
      });
  }, [props.match.params.insightId, firebase]);

  const handleDelete = e => {
    e.preventDefault();

    if (!window.confirm('Are you sure you want to delete?')) {
      return;
    }

    firebase.db
      .collection('insights')
      .doc(insight.id)
      .delete()
      .catch(error => {
        setError(error);
      });

    alert('okay deleted');
    props.history.push('/');
  };

  const handleEdit = e => {
    e.preventDefault();

    setIsEditing(true);
  };

  // Save the new edit and store away the previous one
  // TODO: Move into InsightEditor?
  const handleSubmit = async insightWithEdits => {
    let originalInsight = insight;

    let changesToText = originalInsight.text !== insightWithEdits.text;
    let changesToTags = originalInsight.tags !== insightWithEdits.tags;
    let changesToVisibility =
      originalInsight.isPublic !== insightWithEdits.isPublic;

    if (!changesToText && !changesToTags && !changesToVisibility)
      setIsEditing(false);

    setIsEditing(false);
    setInsight(insightWithEdits);

    // Write old insight text to a new record in the insightEdits collection
    if (changesToText) {
      let writeSuccess;
      await firebase.db
        .collection('insights')
        .doc(insightWithEdits.id)
        .collection('insightEdits')
        .add({
          createdAt: new Date(),
          oldText: originalInsight.text,
        })
        .then(() => {
          writeSuccess = true;
        })
        .catch(error => {
          writeSuccess = false;
          console.log(error);
        });

      if (!writeSuccess) return;
    }

    let fieldsToUpdate = {
      text: insightWithEdits.text,
      lastEditDate: new Date(),
      isPublic: insightWithEdits.isPublic,
    };

    if (insightWithEdits.tags) {
      fieldsToUpdate['tags'] = insightWithEdits.tags;
      fieldsToUpdate['tagsLower'] = insightWithEdits.tagsLower;
    }

    firebase.db
      .collection('insights')
      .doc(insightWithEdits.id)
      .update(fieldsToUpdate)
      .catch(error => {
        setInsight(originalInsight);
      });
  };

  if (!insight) return null;

  const isAuthor = insight.user === currUser.uid;

  return (
    <section id='InsightPage'>
      <section>
        <div className='sectionInner'>
          {error}
          {isEditing && isAuthor ? (
            <InsightEditor
              insight={insight}
              handleSubmit={handleSubmit}
              handleCancel={() => setIsEditing(false)}
            />
          ) : (
            <InsightCard
              insight={insight}
              isAuthor={isAuthor}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          )}
          {!isAuthor && <InsightReact insight={insight} />}
        </div>
      </section>
      <section className='alt-bg'>
        <div className='sectionInner'>
          <CommentsAndEdits
            insightId={insight.id}
            insightText={insight.text}
            insightAuthor={insight.username}
          />
          <ReportContent insightId={insight.id} />
        </div>
      </section>
    </section>
  );
};

export default withRouter(InsightPage);
