import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { withFirebase } from './Firebase';
import * as ROUTES from '../constants/routes.js';

const SignUpPage = () => (
  <section id='SignUpPage'>
    <section>
      <div className='sectionInner'>
        <h1>Getting started</h1>
        <p>
          It's free, and you'll be part of an awesome community of growers.{' '}
          <span role='img' aria-label='Thumbs up'>
            🤙
          </span>
        </p>
        <p>We never sell or share your data.</p>
      </div>
    </section>
    <SignUpForm />
  </section>
);

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.db = this.props.firebase.db;
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    event.preventDefault();
    this.setState({ error: null });

    const { username, email, passwordOne, firstName, lastName } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Register user in firestore as well
        return this.db.collection('users').doc(authUser.user.uid).set({
          username: username,
          email: email,
          firstName: firstName,
          lastName: lastName,
        });
      })
      .then(authUser => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      firstName,
      lastName,
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      firstName === '' ||
      lastName === '' ||
      email === '' ||
      username === '';

    return (
      <section id='SignUp' className='alt-bg'>
        <div className='sectionInner'>
          <form onSubmit={this.onSubmit} className='inputComponent'>
            <input
              name='firstName'
              value={firstName}
              onChange={this.onChange}
              type='text'
              placeholder='First name'
            />
            <input
              name='lastName'
              value={lastName}
              onChange={this.onChange}
              type='text'
              placeholder='Last name'
            />
            <p>
              <input
                name='email'
                value={email}
                onChange={this.onChange}
                type='text'
                placeholder='Email'
              />
            </p>
            <p>
              <input
                name='username'
                value={username}
                onChange={this.onChange}
                type='text'
                placeholder='Username'
                maxLength='22'
              />
              <small>
                For your URL: todayilearned.app/
                {username ? username : 'username'}
              </small>
            </p>
            <p>
              <input
                name='passwordOne'
                value={passwordOne}
                onChange={this.onChange}
                type='password'
                placeholder='Password'
              />
              <input
                name='passwordTwo'
                value={passwordTwo}
                onChange={this.onChange}
                type='password'
                placeholder='Confirm password'
              />
            </p>
            {error && <p>{error.message}</p>}
            <button type='submit' disabled={isInvalid}>
              Sign Up
            </button>
            <p>
              <small>
                We store your data according to our{' '}
                <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link>. Welcome
                onboard!
              </small>
            </p>
          </form>
        </div>
      </section>
    );
  }
}

const SignUpLink = () => <Link to={ROUTES.SIGN_UP}>Create an account</Link>;

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpPage;

export { SignUpForm, SignUpLink };
