import React from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import { Mixpanel } from '../../Mixpanel';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      const user = JSON.parse(localStorage.getItem('authUser'));

      // Send along an updater function to the context
      // that saves to the db and updates the context
      this.updateUser = (key, value) => {
        this.props.firebase.db
          .collection('users')
          .doc(this.state.authUser.uid)
          .update({ [key]: value });

        this.setState(
          {
            authUser: {
              ...this.state.authUser,
              [key]: value,
              update: this.updateUser,
            },
          },
          () => {
            // Cache the results for next page load
            localStorage.setItem(
              'authUser',
              JSON.stringify({ ...this.state.authUser })
            );
          }
        );
      };

      this.state = {
        authUser: {
          ...user,
          update: this.updateUser,
        },
      };

      this.updateUser = this.updateUser.bind(this);
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        async authUser => {
          if (authUser) {
            // Fetch additional user data stored in Firestore
            await this.props.firebase.db
              .collection('users')
              .doc(authUser.uid)
              .get()
              .then(userProfile => {
                let displayName = '';
                if (
                  userProfile.get('firstName') &&
                  userProfile.get('lastName')
                ) {
                  displayName =
                    userProfile.get('firstName') +
                    ' ' +
                    userProfile.get('lastName');
                }

                let user = {
                  username: userProfile.get('username'),
                  firstName: userProfile.get('firstName'),
                  lastName: userProfile.get('lastName'),
                  displayName: displayName,
                  settings: userProfile.get('settings', {}),
                  uid: authUser.uid,
                  email: authUser.email,
                };

                localStorage.setItem('authUser', JSON.stringify(user));
                this.setState({
                  authUser: { ...user, update: this.updateUser },
                });
              });
            Mixpanel.identify(authUser.uid);
          } else {
            localStorage.removeItem('authUser');
            this.setState({ authUser: null });
            Mixpanel.reset();
          }
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
