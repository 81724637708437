import React from 'react';
import styled from 'styled-components';

const MineEncouragements = React.memo(props => {
  const messages = [
    "Whoa, that's an awesome insight.",
    "Oooh, that's an awesome insight.",
    'Keep it coming!',
    'Heeeey, look at you! So wise.',
    'Reflection is self-care. Great job.',
    'A little bit better each day!',
    'Great job!',
    'Great insight!',
    'Awesomesauce.',
    'You saved an insight! Yeah!',
    'Improving is as important as doing.',
    'Keep it coming!',
    'Keep it coming!',
    'Keep it coming!',
    'Nice post!',
    'Well said!',
    'That was a great insight.',
    "Posted! Without mistakes, we wouldn't learn.",
    'This app was built for people like you.',
    'Learning is the highest form of leverage.',
    'Thanks for sharing!',
    'Thanks for sharing!',
    'Great insight!',
    "You've reflected today!",
    "You've reflected today!",
    "You've reflected today!",
    "You've reflected today!",
    "You've reflected today!",
    'You reflected today. Amazing.',
    'Great. Now go invite a friend to TIL!',
    'An insight a day keeps the problems at bay.',
  ];

  let message = messages[Math.floor(Math.random() * messages.length)];

  return (
    <StyledEncouragements>
      <div className='messageOnTop'>
        <ion-icon name='checkmark-outline'></ion-icon>
        {message}
        <button
          className='text'
          onClick={() => {
            props.setAddAnother(true);
          }}
        >
          <ion-icon name='add-circle'></ion-icon> Add another
        </button>
      </div>
    </StyledEncouragements>
  );
});

const StyledEncouragements = styled.div`
  .messageOnTop {
    color: #555 !important;
    margin-bottom: 0px !important;
  }
`;

export default MineEncouragements;
