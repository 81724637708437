import React, { useState, useContext, useEffect } from 'react';

import { AuthUserContext } from './Session';
import { FirebaseContext } from './Firebase';

import Insights from './Insights';
import Spinner from './Spinner';
import InsightEditor from './InsightEditor';
import MineBlankSlate from './MineBlankSlate';
import MineAlreadyPosted from './MineAlreadyPosted';
import MineEncouragements from './MineEncouragements';
import MineStreak from './MineStreak';
import CategoryPickerHeader from './CategoryPickerHeader';

import { SetPageTitle } from '../Utils.js';

const MinePage = props => {
  const currUser = useContext(AuthUserContext) || {};
  const firebase = useContext(FirebaseContext);

  const [loading, setLoading] = useState(true);
  const [insights, setInsights] = useState([]);

  const [justPosted, setJustPosted] = useState(false);
  const [addAnother, setAddAnother] = useState(false);
  const [postedToday, setPostedToday] = useState(false);

  SetPageTitle('Mine');

  // Fetch insights
  useEffect(() => {
    const insightsListener = firebase.db
      .collection('insights')
      .where('user', '==', currUser.uid)
      .orderBy('date', 'desc')
      .onSnapshot(querySnapshot => {
        let newInsights = [];
        querySnapshot.forEach(doc => {
          let insight = doc.data();
          insight.date = insight.date.toDate();
          insight.id = doc.id;
          insight.visibile = true;
          newInsights.push(insight);
        });

        setInsights(newInsights);
        setLoading(false);
      });

    return () => insightsListener();
  }, [currUser.uid, firebase]);

  // Check if there were posts today
  useEffect(() => {
    if (postedToday) return;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    for (let insight of insights) {
      insight.date.setHours(0, 0, 0, 0);
      if (insight.date.getTime() === today.getTime()) {
        setPostedToday(true);
        break;
      }
    }
  }, [insights, postedToday]);

  // Filter by tag, if there is one
  useEffect(() => {
    const tag = props.match.params.tag;

    setInsights(insights => {
      return insights.map(insight => {
        if ((insight.tagsLower && insight.tagsLower.includes(tag)) || !tag) {
          insight.visible = true;
        } else {
          insight.visible = false;
        }

        return insight;
      });
    });
  }, [props.match.params.tag, insights.length]);

  // Redirect to the tag page after posting
  const afterSubmit = insight => {
    setJustPosted(true);
    setAddAnother(false);

    if (insight.tagsLower && insight.tagsLower.length > 0) {
      props.history.push('/mine/' + insight.tagsLower[0]);
    } else {
      props.history.push('/mine');
    }
  };

  if (loading) return <Spinner />;
  if (insights.length === 0)
    return <MineBlankSlate afterSubmit={afterSubmit} />;

  return (
    <section id='Mine'>
      <section>
        <div className='sectionInner'>
          <>
            <MineStreak insights={insights} />
            {addAnother || !postedToday ? (
              <InsightEditor afterSubmit={afterSubmit} />
            ) : justPosted ? (
              <MineEncouragements
                setAddAnother={setAddAnother}
                insightsLen={insights.len}
              />
            ) : (
              <MineAlreadyPosted setAddAnother={setAddAnother} />
            )}
          </>
        </div>
      </section>
      <section className='alt-bg'>
        <div className='sectionInner'>
          <CategoryPickerHeader tag={props.match.params.tag} />
          <Insights insights={insights} list='mine' />
        </div>
      </section>
    </section>
  );
};

export default MinePage;
