import React, { useEffect, useState, useContext } from 'react';

import * as ROUTES from '../constants/routes.js';
import styled from 'styled-components';

import { NavLink } from 'react-router-dom';

import { AuthUserContext } from './Session';
import { FirebaseContext } from './Firebase';

const Navigation = () => (
  <div>
    <AuthUserContext.Consumer>
      {authUser => (authUser ? <NavigationAuthed /> : <NavigationNotAuthed />)}
    </AuthUserContext.Consumer>
  </div>
);

const NavigationAuthed = () => {
  let currUser = useContext(AuthUserContext);
  const firebase = useContext(FirebaseContext);
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    if (!currUser.uid) return;
    let unsub = firebase.db
      .collection('notifications')
      .where('recipientId', '==', currUser.uid)
      .where('read', '==', false)
      .onSnapshot(querySnapshot => {
        setNotificationCount(querySnapshot.size);
      });

    return unsub;
  }, [currUser.uid, firebase.db]);

  return (
    <StyledNavigation>
      <ul className='authed'>
        <li>
          <NavLink
            to={ROUTES.MINE}
            isActive={(match, location) => {
              if (
                location.pathname.match(
                  '/' + currUser.username + '(/|/.*/?)?$'
                ) ||
                location.pathname.match('/mine/?.*?$')
              )
                return true;
              return false;
            }}
          >
            <ion-icon name='home'></ion-icon>
            <div>Mine</div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={ROUTES.PUBLIC}
            isActive={(match, location) => {
              if (
                location.pathname.match('/t/.*$') ||
                (location.pathname.match('/.*/.*$') &&
                  !location.pathname.match('/mine.*$') &&
                  !location.pathname.match(
                    '/' + currUser.username + '(/|/.*/?)?$'
                  )) ||
                location.pathname.match('/public/?$')
              )
                return true;
              return false;
            }}
          >
            <ion-icon name='people-outline'></ion-icon>
            <div>Explore</div>
          </NavLink>
        </li>
        <li>
          <NavLink to={ROUTES.CONVERSATIONS}>
            <ion-icon name='chatbubbles-outline'></ion-icon>
            <div>
              Convos{' '}
              {notificationCount > 0 && (
                <div className='badge'>{notificationCount}</div>
              )}
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink to={ROUTES.SETTINGS}>
            <ion-icon name='cog-outline'></ion-icon>
            <div>Settings</div>
          </NavLink>
        </li>
      </ul>
    </StyledNavigation>
  );
};

const NavigationNotAuthed = () => (
  <ul className='notAuthed'>
    <li>
      <NavLink exact={true} to={ROUTES.HOME}>
        <ion-icon name='home-outline'></ion-icon>
        <div>Home</div>
      </NavLink>
    </li>
    <li>
      <NavLink to={ROUTES.SIGN_UP}>
        <ion-icon name='person-add-outline'></ion-icon>
        <div>Sign up</div>
      </NavLink>
    </li>
    <li>
      <NavLink to={ROUTES.SIGN_IN}>
        <ion-icon name='log-in-outline'></ion-icon>
        <div>Log in</div>
      </NavLink>
    </li>
  </ul>
);

const StyledNavigation = styled.div`
  .authed {
    a {
      position: relative;
      .badge {
        position: absolute;
        top: 4px;
        right: 22px;

        // On desktop, position it better
        @media (min-width: 1024px) {
          right: 28px;
        }
      }
    }
  }
`;

export default Navigation;
