import React from 'react';

import { withFirebase } from './Firebase';

import { CancelPendingNotifications } from '../Utils';

function signOut(firebase) {
  CancelPendingNotifications();
  firebase.doSignOut();
}

const SignOutButton = ({ firebase }) => (
  <button type='submit' onClick={() => (signOut(firebase))}>
    Log out
  </button>
);

export default withFirebase(SignOutButton);
