import React from 'react';
import { withFirebase } from './Firebase';
import { Link, Redirect } from 'react-router-dom';
import { AuthUserContext } from './Session';
import { SetPageTitle } from '../Utils.js';
import * as ROUTES from '../constants/routes.js';
import Insights from './Insights';
import Spinner from './Spinner';
import mascot from '../images/mascot.png';

function Intro() {
  return (
    <section>
      <div className='sectionInner'>
        <div id='Intro'>
          <img src={mascot} id='mascot' />
          <h2>Track your growth, learn from others</h2>
          <p>
            Did you ever wish you could grow faster, without as much pain and
            failure? Now you can! Welcome to the community for personal growth
            geeks, entrepreneurs and mindful people who help one another and
            share their lessons learned.
          </p>
          <p>Here's how it works:</p>
          <ul>
            <li>Post your insights, big or small</li>
            <li>Make them private, or public to get feedback</li>
            <li>See what others are learning and help them grow</li>
            <li>
              Browse top insights by category, like #startups, #life, #parenting
            </li>
            <li>Build your profile</li>
          </ul>
          <p className='actions'>
            <Link to={ROUTES.SIGN_UP}>
              <button>Get started</button>
            </Link>
            <Link to={ROUTES.SIGN_IN}>Log in</Link>
          </p>
        </div>
      </div>
    </section>
  );
}

function HomeInsights(props) {
  if (props.loading) {
    return <Spinner />;
  } else {
    return (
      <section className='alt-bg'>
        <div className='sectionInner'>
          <Insights insights={props.insights} list='public' />
        </div>
      </section>
    );
  }
}

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.db = this.props.firebase.db;

    this.state = {
      insights: [],
      loading: true,
    };
  }

  async componentDidMount() {
    SetPageTitle('Home');

    if (!this.context || !this.context.uid) this.fetchInsights();
  }

  fetchInsights() {
    // Fetch insights
    this.listener = this.db
      .collection('insights')
      .where('isPublic', '==', true)
      .orderBy('date', 'desc')
      .limit(20)
      .onSnapshot(querySnapshot => {
        let insights = [];

        querySnapshot.forEach(doc => {
          let insight = doc.data();
          insight.date = insight.date.toDate();
          insight.id = doc.id;
          insights.push(insight);
        });

        this.setState({ insights: insights });
        this.setState({ loading: false });
      });
  }

  componentWillUnmount() {
    this.listener && this.listener();
  }

  render() {
    if (this.context && this.context.uid) return <Redirect to={ROUTES.MINE} />;

    return (
      <section id='Home'>
        <Intro />
        <HomeInsights
          insights={this.state.insights}
          loading={this.state.loading}
        />
      </section>
    );
  }
}
Home.contextType = AuthUserContext;

export default withFirebase(Home);
