import React, { useEffect } from 'react';
import { useContext } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import '../stylesheets/App.scss';
import { Plugins } from '@capacitor/core';

import Navigation from './Navigation';
import Settings from './Settings';
import Conversations from './Conversations';
import Home from './Home';
import Public from './Public';
import SignUp from './SignUp';
import Mine from './Mine';
import MineCategories from './MineCategories';
import SignIn from './SignIn';
import ResetPassword from './ResetPassword';
import UserProfile from './UserProfile';
import InsightPage from './InsightPage';
import PrivacyPolicy from './PrivacyPolicy';
import SupportPage from './Support';
import TagPage from './TagPage';

import { withAuthentication, AuthUserContext } from './Session';
import * as ROUTES from '../constants/routes.js';

import { ScheduleNotifications } from '../Utils';

const { SplashScreen, LocalNotifications, Device } = Plugins;

const App = () => {
  const currUser = useContext(AuthUserContext);
  SplashScreen.hide();

  /*
   * If the user starts the app on a new device or after a reinstall,
   * we need a way to schedule the notifications that they've asked for.
   */
  useEffect(() => {
    if (
      currUser &&
      currUser.settings &&
      currUser.settings.reminderFreq &&
      currUser.settings.reminderFreq !== 'off'
    ) {
      async function doubleCheckNotifs() {
        let deviceInfo = await Device.getInfo();
        if (deviceInfo.platform === 'web') return;

        let notifsEnabled = await LocalNotifications.areEnabled();
        if (notifsEnabled.value) {
          let pendingNotifs = await LocalNotifications.getPending();
          if (pendingNotifs.notifications.length === 0) {
            ScheduleNotifications(currUser.settings.reminderFreq);
          }
        } else {
          let permissions = await LocalNotifications.requestPermission();
          if (permissions.granted) {
            ScheduleNotifications(currUser.settings.reminderFreq);
          } else {
            alert('Okay, no problem.');
          }
        }
      }
      doubleCheckNotifs();
    }
  }, [currUser]);

  return (
    <div className={currUser && currUser.email ? 'loggedIn app' : 'app'}>
      <Router>
        {currUser && currUser.email && (
          <header className='sidebar'>
            <Navigation />
          </header>
        )}

        <div
          className={
            currUser && currUser.email ? 'mainContent loggedIn' : 'mainContent'
          }
        >
          <header id='Logo'>
            <h1>todayilearned.app</h1>
          </header>
          <Switch>
            <Route exact path={ROUTES.HOME} component={Home} />
            <Route path={ROUTES.MINE_CATEGORIES} component={MineCategories} />
            <Route path={ROUTES.MINE_WITH_TAG} component={Mine} />
            <Route path={ROUTES.MINE} component={Mine} />
            <Route path={ROUTES.PUBLIC} component={Public} />
            <Route path={ROUTES.SETTINGS} component={Settings} />
            <Route path={ROUTES.CONVERSATIONS} component={Conversations} />
            <Route path={ROUTES.SIGN_UP} component={SignUp} />
            <Route path={ROUTES.SIGN_IN} component={SignIn} />
            <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
            <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
            <Route path={ROUTES.SUPPORT_PAGE} component={SupportPage} />
            <Route path={ROUTES.TAG_PAGE} component={TagPage} />
            <Route path={ROUTES.INSIGHT_PAGE} component={InsightPage} />
            <Route path={ROUTES.USER_PROFILE} component={UserProfile} />
          </Switch>
        </div>
      </Router>
    </div>
  );
};

export default withAuthentication(App);
