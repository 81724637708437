/*
 * Checks if logged in user has blocked any users and displays a button in Settings if they have
 * Clicking the button displays a list of blocked users and allows the user to unblock them
 */

import React, { Component } from 'react';
import { withFirebase } from './Firebase';
import { AuthUserContext } from './Session';

import { FetchBlockedUsers } from '../Utils';

const BlockedUserBtn = props => {
  return (
    <tr>
      <td>{props.username} </td>
      <td className='td-btn'>
        <button onClick={props.handleUnblock}>Unblock</button>
      </td>
    </tr>
  );
};

class BlockedUsers extends Component {
  constructor(props) {
    super(props);

    this.db = this.props.firebase.db;
    this.handleUnblock = this.handleUnblock.bind(this);
    this.showForm = this.showForm.bind(this);

    this.state = {
      showForm: false,
      blockedUsers: null,
    };
  }

  async componentDidMount() {
    let blockedUsers = await FetchBlockedUsers(this.db, this.context.uid);
    this.setState({ blockedUsers: blockedUsers });
  }

  handleUnblock(blockedUser, e) {
    // delete record from blockedUsers collection
    this.db
      .collection('blockedUsers')
      .doc(blockedUser.id)
      .delete()
      .then(async () => {
        let blockedUsers = await FetchBlockedUsers(this.db, this.context.uid);
        this.setState({ blockedUsers: blockedUsers });
      });
  }

  showForm = event => {
    this.setState({ showForm: true });
  };

  render() {
    // if database read has finished and blockedUsers has been populated in state,
    // check if there are any blockedUsers and display button if there are
    let blockedUsersBtn = '';
    if (this.state.blockedUsers !== null) {
      if (this.state.blockedUsers.docs.length > 0 && !this.state.showForm) {
        blockedUsersBtn = (
          <button type='button' onClick={this.showForm}>
            Manage Blocked Users
          </button>
        );
      }
    }

    return (
      <section className='inputComponent' id='BlockedUsersSection'>
        {blockedUsersBtn}
        {this.state.showForm && (
          <div>
            <h3>Blocked Users</h3>
            <div>
              <table id='BlockedUsersTable'>
                <tbody>
                  {this.state.blockedUsers.docs.map((blockedUser, index) => (
                    <BlockedUserBtn
                      username={blockedUser.get('blockedUser')}
                      handleUnblock={e => this.handleUnblock(blockedUser, e)}
                      key={'blockedUser-' + index}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </section>
    );
  }
}

BlockedUsers.contextType = AuthUserContext;

export default withFirebase(BlockedUsers);
