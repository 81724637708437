import React, { useState, useContext, useEffect } from 'react';

import { AuthUserContext } from './Session';
import { FirebaseContext } from './Firebase';

import Categories from './Categories';
import Spinner from './Spinner';

import { SetPageTitle } from '../Utils.js';

const MinePage = props => {
  const currUser = useContext(AuthUserContext) || {};
  const firebase = useContext(FirebaseContext);

  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  SetPageTitle('Mine');

  // Fetch insights
  useEffect(() => {
    const insightsListener = firebase.db
      .collection('insights')
      .where('user', '==', currUser.uid)
      .orderBy('date', 'desc')
      .onSnapshot(querySnapshot => {
        let newCategories = [];
        querySnapshot.forEach(doc => {
          let tags = doc.data().tags;
          if (tags && tags.length > 0) {
            tags.forEach(element => newCategories.push(element));
          }
        });

        // Count category entries
        let uniqueCategories = {};
        newCategories.map((el, i) => {
          return (uniqueCategories[newCategories[i]] =
            1 + (uniqueCategories[newCategories[i]] || 0));
        });

        setCategories(uniqueCategories);
        setLoading(false);
      });

    return () => insightsListener();
  }, [currUser.uid, firebase]);

  if (loading) return <Spinner />;

  return (
    <section id='Mine'>
      <section className='alt-bg'>
        <div className='sectionInner'>
          <Categories categories={categories}></Categories>
        </div>
      </section>
    </section>
  );
};

export default MinePage;
