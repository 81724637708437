import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: process.env.REACT_APP_PROD_API_KEY,
  authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
  projectId: process.env.REACT_APP_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PROD_APP_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.firestore = app.firestore;
    this.db = app.firestore();
    // TODO: Make this work in the iOS Simulator. Maybe using deviceInfo.isVirtual.
    if (process.env.NODE_ENV === 'development') {
      this.db.useEmulator('localhost', 8080);
      this.auth.useEmulator('http://localhost:9099/');
    }
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPassWordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  doReauthenticate = async password => {
    const user = this.auth.currentUser;
    const credential = await app.auth.EmailAuthProvider.credential(
      user.email,
      password
    );
    return await user.reauthenticateWithCredential(credential);
  };
}

export default Firebase;
