import React from 'react';
import { withFirebase } from './Firebase';
import { AuthUserContext } from './Session';

import AddComment from './AddComment';
import Comment from './Comment';
import Reaction from './Reaction';
import { FetchBlockedUsers } from '../Utils.js';

class Comments extends React.Component {
  constructor(props) {
    super(props);

    this.db = this.props.firebase.db;
    this.sortCommentsAndReactions = this.sortCommentsAndReactions.bind(this);

    this.state = {
      comments: [],
      reactions: [],
      commentsAndReactions: [],
    };
  }

  async componentDidMount() {
    // First, we need to fetch any users the logged-in user has blocked
    let blockedUsers = this.context
      ? await FetchBlockedUsers(this.db, this.context.uid)
      : [];

    // Second, we need to fetch any comments ("reflections") ...
    await this.db
      .collection('insights')
      .doc(this.props.insightId)
      .collection('comments')
      .orderBy('createdAt', 'asc')
      .onSnapshot(querySnapshot => {
        let comments = [];
        querySnapshot.forEach(comment => {
          /* Filter out blocked users comments
           * For efficiency don't go through the loop if there are no blocked users or if this
           * is a comment from the logged in user
           */
          if (
            blockedUsers.size > 0 &&
            comment
              .data()
              .userRef.isEqual(this.db.doc('users/' + this.context.uid)) ===
              false
          ) {
            // Compare userRef from comment to those of users who have been blocked.
            // If they match, skip displaying this comment
            for (let blockedUser of blockedUsers.docs) {
              if (
                comment
                  .data()
                  .userRef.isEqual(blockedUser.get('blockedUserRef'))
              ) {
                return;
              }
            }
          }

          // add comment.id so can identify correct comment when editing
          comments.push(Object.assign(comment.data(), { id: comment.id }));
        });
        this.setState({ comments: comments });
      });

    // ... then we're also fetching any reactions
    this.db
      .collection('insights')
      .doc(this.props.insightId)
      .collection('reactions')
      .orderBy('createdAt', 'asc')
      .onSnapshot(querySnapshot => {
        let reactions = [];

        querySnapshot.docs.forEach((r, i) => {
          reactions.push({
            createdAt: r.get('createdAt'),
            displayName: r.get('displayName'),
            username: r.get('username'),
            type: r.get('type'),
            reaction: true,
          });
        });
        this.setState({ reactions: reactions });
      });
  }

  sortCommentsAndReactions() {
    // if there are reactions and comments, merge and sort them
    if (this.state.comments.length > 0 && this.state.reactions.length > 0) {
      let result = this.state.comments
        .concat(this.state.reactions)
        .sort((a, b) => a.createdAt.toDate() - b.createdAt.toDate());
      return result;
    } else if (this.state.comments.length > 0) {
      return this.state.comments;
    } else if (this.state.reactions.length > 0) {
      return this.state.reactions;
    }

    return [];
  }

  render() {
    const commentsAndReactions = this.sortCommentsAndReactions().map(
      (commentOrReaction, index) => (
        <div key={index}>
          {commentOrReaction.reaction === true ? (
            <Reaction reaction={commentOrReaction} />
          ) : (
            <Comment
              comment={commentOrReaction}
              insightId={this.props.insightId}
            />
          )}
        </div>
      )
    );

    return (
      <section id='Comments'>
        {commentsAndReactions}
        <AddComment insightId={this.props.insightId} />
      </section>
    );
  }
}

Comments.contextType = AuthUserContext;

export default withFirebase(Comments);
