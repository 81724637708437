import React, { Component } from 'react';

import { withFirebase } from './Firebase';

import Insights from './Insights';
import Spinner from './Spinner';
import BackButton from './BackButton';

import { SetPageTitle } from '../Utils.js';

class TagPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      loading: true,
      insights: []
    };

    this.fetchData = this.fetchData.bind(this);
  }

  fetchData() {
    // Fetch this user's insights
    this.listener = this.props.firebase.db
      .collection('insights')
      .where('isPublic', '==', true)
      .where('tagsLower', 'array-contains', this.props.match.params.tag.toLowerCase())
      .orderBy('date', 'desc')
      .onSnapshot(querySnapshot => {
        this.setState({
          loading: false,
        });

        if (querySnapshot.empty)
          this.setState({ error: 'We found no publicly shared insights with that tag name.' });

        let insights = [];
        querySnapshot.forEach(doc => {
          let insight = doc.data();
          insight.date = insight.date.toDate();
          insight.id = doc.id;
          insights.push(insight);

          this.setState({ insights: insights });
        });
      });
  }

  componentDidMount() {
    SetPageTitle('#' + this.props.match.params.tag);
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.tag !== prevProps.match.params.tag)
      this.fetchData();
  }

  componentWillUnmount() {
    this.listener && this.listener();
  }

  render() {
    if (this.state.loading) return <Spinner/>;

    return (
      <section id='TagPage'>
        <section>
          <div className='sectionInner'>
            <BackButton/>
            <h1>#{this.props.match.params.tag}</h1>
          </div>
        </section>
        <section className="alt-bg">
          <section className="sectionInner">
            {this.state.error}
            <Insights insights={this.state.insights} list='public' />
          </section>
        </section>
      </section>
    );
  }
}

export default withFirebase(TagPage);
