import React from 'react';

import { withFirebase } from './Firebase';
import { AuthUserContext } from './Session';

import Insights from './Insights';
import Spinner from './Spinner';

import { SetPageTitle, FetchBlockedUsers } from '../Utils.js';

class Public extends React.Component {
  constructor(props) {
    super(props);

    this.db = this.props.firebase.db;

    this.state = {
      insights: [],
      limit: 60,
      loading: true,
      everythingLoaded: false,
    };

    this.incrementAmount = 25;

    this.loadMoreInsights = this.loadMoreInsights.bind(this);
    this.fetchInsights = this.fetchInsights.bind(this);
  }

  componentDidMount() {
    SetPageTitle('Explore');

    this.fetchInsights();
  }

  async fetchInsights() {
    let blockedUsers = this.context ? await FetchBlockedUsers(this.db, this.context.uid) : [];

    this.listener = this.db
      .collection('insights')
      .where('isPublic', '==', true)
      .orderBy('date', 'desc')
      .limit(this.state.limit)
      .onSnapshot(querySnapshot => {
        let insights = [];

        querySnapshot.forEach(doc => {
          let insight = doc.data();

          /* For efficiency don't go through the loop if there are no blocked users or if this
           * is the logged in user's insight or no userRef field exists (last one is fix for dev environment)
           */
          if (blockedUsers.size > 0 && insight.user !== this.context.uid && insight.hasOwnProperty('userRef')) {
            // Compare userRef from insight to those of users who have been blocked.
            // If they match, skip displaying this insight
            for (let blockedUser of blockedUsers.docs) {
              if (insight.userRef.isEqual(blockedUser.get('blockedUserRef'))) {
                return;
              }
            }
          }

          insight.date = insight.date.toDate();
          insight.id = doc.id;

          insights.push(insight);
        });

        this.setState({ insights: insights });
        this.setState({ loading: false });

        // Check if too few documents came through...
        if (querySnapshot.docChanges().length < this.incrementAmount) {
          this.setState({
            everythingLoaded: true,
          });
        }
      });
  }

  componentWillUnmount() {
    this.listener && this.listener();
  }

  loadMoreInsights() {
    this.setState(
      state => ({
        limit: state.limit + this.incrementAmount,
      }),
      this.fetchInsights
    );
  }

  render() {
    let loadMoreButton;

    if (!this.state.everythingLoaded) {
      loadMoreButton = (
        <button type='button' onClick={this.loadMoreInsights}>
          Load more
        </button>
      );
    } else {
      loadMoreButton = (
        <button type='button' disabled='true'>
          Load more
        </button>
      );
    }

    if (this.state.loading) {
      return <Spinner />;
    } else {
      return (
        <section id='PublicInsights'>
          <section className='alt-bg'>
            <div className='sectionInner'>
              <div>
                <Insights insights={this.state.insights} list='public' />
                <p>{loadMoreButton}</p>
              </div>
            </div>
          </section>
        </section>
      );
    }
  }
}
Public.contextType = AuthUserContext;

export default withFirebase(Public);
