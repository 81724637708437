import React, { useEffect, useState } from 'react';

import { withRouter, useHistory } from 'react-router-dom';
import { Plugins } from '@capacitor/core';

const { Device } = Plugins;

function BackButton() {
  const [deviceInfo, setDeviceInfo] = useState({});

  useEffect(() => {
    (async () => {
      const deviceInfo = await Device.getInfo();
      setDeviceInfo(deviceInfo);
    })();
  }, []);

  let history = useHistory();

  function handleBack() {
    history.goBack();
  }

  if (deviceInfo.platform !== 'ios') return null;

  return (
    <div className='backButtonContainer'>
      <button onClick={handleBack} className='unstyled'>
        ←
      </button>
    </div>
  );
}

export default withRouter(BackButton);
