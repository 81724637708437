import { SITE_TITLE } from './constants/site.js';
import { Plugins } from '@capacitor/core';

function GetMonthAndYear(date) {
  return (
    date.toLocaleString('default', { month: 'long' }) + ' ' + date.getFullYear()
  );
}

function GetDayMonthAndYearIfLastYear(date) {
  let returnDate;
  let today = new Date();

  returnDate =
    date.toLocaleString('default', { month: 'short' }) + ' ' + date.getDate();

  if (today.getFullYear() !== date.getFullYear()) {
    returnDate += " '" + date.getFullYear().toString().substring(2, 4);
  }

  return returnDate;
}

function GetDayMonthAndYear(date) {
  return (
    date.toLocaleString('default', { month: 'short' }) +
    ' ' +
    date.getDate() +
    ', ' +
    date.getFullYear()
  );
}

function SetPageTitle(pageTitle) {
  document.title = `${pageTitle} — ${SITE_TITLE}`;
}

function SanitizeText(text) {
  // trim and replace 3 or more new line chars with 2 new lines
  return text.replace(/[\r\n]{3,}/g, '\n\n').trim();
}

async function FetchBlockedUsers(db, loggedInUid) {
  // We surround this in a promise to force the call to wait for it to finish running before continuing
  let blockedUsers = new Promise(async (resolve, reject) => {
    let loggedInUserRef = db.doc('users/' + loggedInUid);

    let blockedUsersData = await db
      .collection('blockedUsers')
      .where('blockedByRef', '==', loggedInUserRef)
      .get()
      .catch(err => {
        reject(err);
      });

    resolve(blockedUsersData);
  });

  return blockedUsers;
}

/*
 * Create recurring notifications on mobile devices, given a specified interval.
 * Note: Cannot be used on web, as the LocalNotification methods aren't available there.
 */
async function ScheduleNotifications(interval) {
  const { LocalNotifications } = Plugins;

  if (interval === 'off' || !interval) return;

  let notification = [];
  if (interval === 'daily') {
    notification = [
      {
        id: 1,
        schedule: {
          on: {
            hour: 20, // 8pm, every day
            minute: 0,
          },
        },
      },
    ];
    /*
   * XXX: Here be dragons. Capacitor doesn't support `weekday`. Revisit later.
   * Filed a feature request with Capacitor: https://github.com/ionic-team/capacitor/issues/2991
   * Idea: Could maybe use multiple `day` notifications, to create an every third day effect.
   * That would involve using `on` and `hour: 20` and `day`: [1, 4, 7, 10 ...]
   *
  } else if (interval === 'twice-a-week') {
    notification = [{
      id: 2,
      schedule: {
        on: {
          minute: 45,
          hour: 11,
          day: 1
        }
      }
    },{
      id: 3,
      schedule: {
        on: {
          minute: 46,
          hour: 11,
          day: 1
        }
      }
    }];
   */
  }

  LocalNotifications.schedule({
    notifications: notification.map(notification => ({
      title: 'What did you learn today?',
      body: '',
      id: notification.id,
      schedule: notification.schedule,
      sound: null,
      attachments: null,
      actionTypeId: '',
      extra: null,
    })),
  });
}

/*
 * If a device has upcoming scheduled notifications, we sometimes need to clear those.
 */
async function CancelPendingNotifications() {
  const { LocalNotifications } = Plugins;

  let pending = await LocalNotifications.getPending();
  if (pending.notifications.length > 0) {
    LocalNotifications.cancel(pending);
    return;
  }
}

function GetSetting(key, currUser) {
  if (!currUser) return '';
  if (!currUser.settings) return '';
  return currUser.settings[key];
}

export default GetMonthAndYear;

export {
  GetMonthAndYear,
  GetDayMonthAndYear,
  GetDayMonthAndYearIfLastYear,
  SetPageTitle,
  SanitizeText,
  FetchBlockedUsers,
  ScheduleNotifications,
  CancelPendingNotifications,
  GetSetting,
};
