import React, { useEffect, useState } from 'react';

import { withFirebase } from './Firebase';

import Insights from './Insights';
import Spinner from './Spinner';
import BlockUser from './BlockUser';
import BackButton from './BackButton';
import styled from 'styled-components';

import { SetPageTitle } from '../Utils.js';

const UserProfile = props => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [insights, setInsights] = useState([]);
  const [user, setUser] = useState({});

  SetPageTitle('User profile');

  useEffect(() => {
    // Fetch this user
    props.firebase.db
      .collection('users')
      .where('username', '==', props.match.params.username)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          setUser({
            firstName: doc.get('firstName'),
            lastName: doc.get('lastName'),
          });
        });
      });

    // Fetch this user's insights
    const listener = props.firebase.db
      .collection('insights')
      .where('username', '==', props.match.params.username)
      .where('isPublic', '==', true)
      .orderBy('date', 'desc')
      .onSnapshot(querySnapshot => {
        setLoading(false);

        if (querySnapshot.empty)
          setError('No such user, or no public insights shared.');

        let innerInsights = [];
        querySnapshot.forEach(doc => {
          let insight = doc.data();
          insight.date = insight.date.toDate();
          insight.id = doc.id;
          innerInsights.push(insight);
        });
        setInsights(innerInsights);
      });

    return () => {
      listener && listener();
    };
  }, [props.firebase, props.match.params.username]);

  if (loading) {
    return <Spinner />;
  } else {
    return (
      <section id='UserProfile' className={props.className}>
        <section>
          <div className='sectionInner'>
            <BackButton />
            <div id='Username'>
              <h1>
                {user.firstName} {user.lastName}
              </h1>
              <span>{props.match.params.username}</span>
              <BlockUser username={props.match.params.username} />
            </div>
          </div>
        </section>
        <section className='alt-bg'>
          <section className='sectionInner'>
            {error}
            <Insights insights={insights} list='userprofile' />
          </section>
        </section>
      </section>
    );
  }
};

const StyledUserProfile = styled(UserProfile)`
  #Username {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    h1 {
      font-size: 34px;
      margin: 0 0 7px;
    }

    #BlockUser {
      #BlockUserLink {
        cursor: pointer;
        color: #bbb;

        &:hover {
          color: #353535;
        }

        ion-icon {
          position: relative;
          top: 2px;
        }
      }
    }
  }

  /* Time Ago Tooltip CSS */

  .rrui__tooltip {
    /* Display the tooltip above the content. */
    margin-top: -0.5rem;
    background-color: black;
    color: white;
    padding: 0.3em;
    font-size: 0.8em;
  }

  .rrui__tooltip--after-show {
    opacity: 0.85;
  }

  .rrui__tooltip--before-hide {
    opacity: 0;
  }

  .rrui__tooltip__target {
    /* Prevents entering text selection mode
    on mobile devices when tapping down and holding */
    user-select: none;
    /* Resets cursor: pointer set by interactive={true}'s .rrui__button-reset. */
    cursor: inherit;
    /* Resets white-space : nowrap set by interactive={true}'s .rrui__button-reset. */
    white-space: initial;
  }
`;

export default withFirebase(StyledUserProfile);
